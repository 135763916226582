import { useCallback, useEffect, useState } from "react";
import { Box, Button, styled, TextField } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { updateAppConfigItem } from "../../services/app.service";

const FormContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
});

const defaultValues = {
  key: "",
  value: "",
};

const SettingsForm = ({ isShows, setIsShown, onSuccess }) => {
  const [item, setItem] = useState(defaultValues);
  const {
    mutate: updateItem,
    isPending: isUpdatePending,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    reset,
  } = useMutation({
    mutationKey: ["createAppConfigItem"],
    mutationFn: (item) => updateAppConfigItem(item),
  });

  const handleChange = useCallback((event) => {
    setItem((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const onCreate = useCallback(() => {
    if (!item.key || !item.value) {
      alert("Please, set key and value");
      return;
    }

    updateItem({ ...item });
  }, [updateItem, item]);

  const onCancel = useCallback(() => {
    setItem(defaultValues);
    setIsShown(false);
  }, [setIsShown]);

  useEffect(() => {
    if (isUpdateSuccess) {
      reset();
      setItem(defaultValues);
      setIsShown(false);
      onSuccess();

      alert("Success");
    }
  }, [isUpdateSuccess, reset, setIsShown, onSuccess]);

  useEffect(() => {
    if (isUpdateError) {
      alert("Error");
    }
  }, [isUpdateError]);

  return isShows ? (
    <FormContainer>
      <TextField
        sx={{ mr: 2 }}
        required
        id="key"
        name="key"
        label="Key"
        disabled={isUpdatePending}
        value={item.key}
        onChange={handleChange}
      />

      <TextField
        sx={{ mr: 2 }}
        required
        id="value"
        name="value"
        label="Value"
        disabled={isUpdatePending}
        value={item.value}
        onChange={handleChange}
      />

      <Button
        sx={{ mr: 2 }}
        variant="contained"
        disabled={isUpdatePending}
        onClick={onCreate}
      >
        Save
      </Button>

      <Button
        sx={{ mr: 2 }}
        variant="outlined"
        disabled={isUpdatePending}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </FormContainer>
  ) : null;
};

export default SettingsForm;
