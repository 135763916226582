import { apiGet, apiPut } from "../utils";

export const VerificationRequestStatus = {
  Pending: "pending",
  Fulfilled: "fulfilled",
  Cancelled: "cancelled",
};

export const getVerificationRequests = async (page, perPage) => {
  const response = await apiGet("/verification-requests", { page, perPage });
  const { items, totalCount, message } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return { items, totalCount };
};

export const getVerificationRequest = async (id) => {
  const response = await apiGet(`/verification-requests/${id}`);
  const { message, ...verificationRequest } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return verificationRequest;
};

export const changeVerificationRequestStatus = async (id, status) => {
  const response = await apiPut(`/verification-requests/${id}/status`, {
    status,
  });
  const { message, ...verificationRequest } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return verificationRequest;
};
