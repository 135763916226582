import * as React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import VerifiedIcon from "@mui/icons-material/Verified";
import MicIcon from "@mui/icons-material/Mic";
import SettingsIcon from "@mui/icons-material/Settings";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";

export const items = (
  <React.Fragment>
    <ListItemButton component={Link} to={"/reports"}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton component={Link} to={"/verification-requests"}>
      <ListItemIcon>
        <VerifiedIcon />
      </ListItemIcon>
      <ListItemText primary="Verification requests" />
    </ListItemButton>
    <ListItemButton component={Link} to={"/shows"}>
      <ListItemIcon>
        <MicIcon />
      </ListItemIcon>
      <ListItemText primary="Shows" />
    </ListItemButton>
    <ListItemButton component={Link} to={"/users"}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItemButton>
    <ListItemButton component={Link} to={"/create-notifications"}>
      <ListItemIcon>
        <NotificationAddIcon />
      </ListItemIcon>
      <ListItemText primary="Create notifications" />
    </ListItemButton>
    <ListItemButton component={Link} to={"/settings"}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
  </React.Fragment>
);
