import { format } from "date-fns";
import CustomTable from "../../components/CustomTable";
import { getVerificationRequests } from "../../services/verificationRequest.service";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { StyledTableCell } from "../../components/styled";

const renderHeader = () => (
  <TableRow>
    <StyledTableCell>Name</StyledTableCell>
    <StyledTableCell>Contact</StyledTableCell>
    <StyledTableCell>Birthday</StyledTableCell>
    <StyledTableCell>Role</StyledTableCell>
    <StyledTableCell>Position</StyledTableCell>
    <StyledTableCell>Club</StyledTableCell>
    <StyledTableCell>Status</StyledTableCell>
    <StyledTableCell>Created at</StyledTableCell>
    <StyledTableCell>Actions</StyledTableCell>
  </TableRow>
);

const renderRow = (request) => (
  <TableRow key={request.id}>
    <TableCell>{request.fullName}</TableCell>
    <TableCell>{request.contact}</TableCell>
    <TableCell>{format(request.birthday, "MMM d, yyyy H:mm")}</TableCell>
    <TableCell>{request.role}</TableCell>
    <TableCell>{request.position || "N/A"}</TableCell>
    <TableCell>{request.club || "N/A"}</TableCell>
    <TableCell>{request.status}</TableCell>
    <TableCell>{format(request.createdAt, "MMM d, yyyy H:mm")}</TableCell>
    <TableCell>
      <IconButton aria-label="view" component={Link} to={`${request.id}`}>
        <VisibilityIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const VerificationRequests = () => {
  return (
    <CustomTable
      name={"requests"}
      getItemsMethod={getVerificationRequests}
      renderHeader={renderHeader}
      renderRow={renderRow}
      columnCount={9}
    />
  );
};

export default VerificationRequests;
