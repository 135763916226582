import { useEffect } from "react";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  changeReportStatus,
  getReport,
  ReportStatus,
} from "../../services/report.service";
import { ErrorPageDetails } from "../../components/ErrorPageDetails";
import { Loading } from "../../components/Loading";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DetailsLabel from "../../components/DetailsLabel";
import { format } from "date-fns";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

const InnerContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
});
const LeftSideContainer = styled(Box)({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  padding: "0 8px",
});
const RightSideContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 8px",
});
const AvatarContainer = styled(Box)({
  position: "relative",
  width: "150px",
  height: "150px",
});
const AvatarBackground = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 0,
});
const AvatarImage = styled("img")({
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 10,
});

const ReportView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["report", id],
    queryFn: () => getReport(id),
  });
  const {
    mutate: changeStatus,
    isPending,
    isSuccess: isChangeStatusSuccess,
  } = useMutation({
    mutationKey: ["reportStatus", id],
    mutationFn: (status) => changeReportStatus(id, status),
  });

  useEffect(() => {
    if (isChangeStatusSuccess) {
      navigate(-1);
    }
  }, [isChangeStatusSuccess, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPageDetails text={"Error loading report"} />;
  }

  return (
    <Container component="main">
      <InnerContainer>
        <LeftSideContainer>
          <Box>
            <Typography component="h1" variant="h4">
              Reporter Info
            </Typography>
            <AvatarContainer>
              <AvatarBackground
                src={data.source.background}
                alt={"User background"}
                loading="lazy"
              />
              <AvatarImage
                src={data.source.image}
                alt={"User avatar"}
                loading="lazy"
              />
            </AvatarContainer>
            <DetailsLabel label={"Name"} value={data.source.name} />
            <DetailsLabel label={"Username"} value={data.source.username} />
            <DetailsLabel label={"Score"} value={data.source.score} />
            <DetailsLabel
              label={"Verified as"}
              value={data.source.verifiedAs || "none"}
            />
          </Box>
          <Box>
            <Typography component="h1" variant="h4">
              Target Info
            </Typography>
            <AvatarContainer>
              <AvatarBackground
                src={data.target.background}
                alt={"User background"}
                loading="lazy"
              />
              <AvatarImage
                src={data.target.image}
                alt={"User avatar"}
                loading="lazy"
              />
            </AvatarContainer>
            <DetailsLabel label={"Name"} value={data.target.name} />
            <DetailsLabel label={"Username"} value={data.target.username} />
            <DetailsLabel label={"Score"} value={data.target.score} />
            <DetailsLabel
              label={"Verified as"}
              value={data.source.verifiedAs || "none"}
            />
          </Box>
        </LeftSideContainer>
        <RightSideContainer>
          <Typography component="h1" variant="h4">
            Report Info
          </Typography>
          <DetailsLabel label={"Type"} value={data.type} />
          <DetailsLabel label={"Description"} value={data.description} />
          <DetailsLabel label={"Status"} value={data.status} />
          <DetailsLabel
            label={"Date of incident"}
            value={format(data.incidentDate, "MMM d, yyyy H:mm")}
          />
          <DetailsLabel
            label={"Created at"}
            value={format(data.createdAt, "MMM d, yyyy H:mm")}
          />
        </RightSideContainer>
      </InnerContainer>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: "16px",
        }}
      >
        <Button
          variant="outlined"
          disabled={isPending}
          onClick={() => changeStatus(ReportStatus.Ignored)}
        >
          Ignore
        </Button>
        {data.status === ReportStatus.UserBlocked ? (
          <Button
            variant="contained"
            disabled={isPending}
            onClick={() => changeStatus(ReportStatus.UserRestored)}
          >
            Restore User
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled={isPending}
            onClick={() => changeStatus(ReportStatus.UserBlocked)}
          >
            Block User
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default ReportView;
