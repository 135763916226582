import { apiPost } from "../utils";

export const createNotification = async (data) => {
  const response = await apiPost(`/admin/notifications`, data);

  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};
