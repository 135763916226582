import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

export const ErrorPageDetails = ({ text }) => (
  <Container
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }}
  >
    <Typography component="h1" variant="h3">
      {text}
    </Typography>
  </Container>
);
