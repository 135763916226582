import { useCallback, useEffect, useMemo, useState } from "react";
import { Box, IconButton, styled, TextField } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DetailsLabel from "../../components/DetailsLabel";
import { useMutation } from "@tanstack/react-query";
import {
  deleteAppConfigItem,
  updateAppConfigItem,
} from "../../services/app.service";

const ItemContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  marginBlock: "16px",
});

const SettingsItem = ({ label, currentValue, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(currentValue);
  const {
    mutate: updateItem,
    isError: isUpdateError,
    isSuccess: isUpdateSuccess,
    isPending: isUpdatePending,
    reset: updateItemReset,
  } = useMutation({
    mutationKey: ["updateAppConfigItem"],
    mutationFn: (item) => updateAppConfigItem(item),
  });
  const {
    mutate: deleteItem,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
    isPending: isDeletePending,
    reset: deleteItemReset,
  } = useMutation({
    mutationKey: ["deleteAppConfigItem"],
    mutationFn: (params) => deleteAppConfigItem(params),
  });
  const sx = useMemo(() => {
    return {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: !isEditing ? "none" : "",
        },
        "& input": {
          boxSizing: "border-box",
        },
      },
      mr: 2,
    };
  }, [isEditing]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onChange = useCallback((event) => {
    setValue(event.target.value);
  }, []);

  const handleSave = useCallback(() => {
    updateItem({ key: label, value });
    setIsEditing(false);
  }, [updateItem, label, value]);

  const onClose = useCallback(() => {
    setValue(currentValue);
    setIsEditing(false);
  }, [currentValue]);

  const onDelete = useCallback(
    (key) => {
      if (!key) {
        return;
      }

      deleteItem({ key });
    },
    [deleteItem],
  );

  useEffect(() => {
    if (isDeleteSuccess) {
      deleteItemReset();
      onSuccess();
      alert("Success");
    }
  }, [isDeleteSuccess, deleteItemReset, onSuccess]);

  useEffect(() => {
    if (isUpdateSuccess) {
      updateItemReset();
      onSuccess();
      alert("Success");
    }
  }, [isUpdateSuccess, updateItemReset, onSuccess]);

  useEffect(() => {
    if (isUpdateError || isDeleteError) {
      alert("Error");
    }
  }, [isUpdateError, isDeleteError]);

  return (
    <ItemContainer>
      <DetailsLabel label={label} />

      <TextField
        required
        id="value"
        name="value"
        disabled={!isEditing}
        value={value}
        onChange={onChange}
        sx={sx}
      />

      {isEditing ? (
        <>
          <IconButton
            variant="contained"
            disabled={isUpdatePending || isDeletePending}
            onClick={handleSave}
            sx={{ marginInline: 1 }}
          >
            <CheckIcon />
          </IconButton>

          <IconButton
            variant="contained"
            onClick={onClose}
            sx={{ marginInline: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            variant="contained"
            onClick={onEdit}
            sx={{ marginInline: 1 }}
          >
            <CreateIcon />
          </IconButton>
          <IconButton
            variant="contained"
            disabled={isUpdatePending || isDeletePending}
            onClick={() => onDelete(label)}
            sx={{ marginInline: 1 }}
          >
            <DeleteIcon style={{ fill: "red" }} />
          </IconButton>
        </>
      )}
    </ItemContainer>
  );
};

export default SettingsItem;
