import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
} from "react-router-dom";
import Reports from "../screens/reports/List";
import ReportView from "../screens/reports/View";
import VerificationRequests from "../screens/verificationRequests/List";
import VerificationRequestView from "../screens/verificationRequests/View";
import DashboardContainer from "../components/DashboardContainer";
import Welcome from "../screens/Welcome";
import Login from "../screens/Login";
import Users from "../screens/users/List";
import UserView from "../screens/users/View";
import CreateNotifications from "../screens/createNotifications/Form";
import Shows from "../screens/shows/List";
import ShowView from "../screens/shows/View";
import Settings from "../screens/settings/View";

const isAuthenticatedGuard = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return redirect("/login");
  }

  return null;
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<DashboardContainer />} loader={isAuthenticatedGuard}>
        <Route index element={<Welcome />} />
        <Route path="reports" element={<Reports />} />
        <Route path="reports/:id" element={<ReportView />} />
        <Route
          path="verification-requests"
          element={<VerificationRequests />}
        />
        <Route
          path="verification-requests/:id"
          element={<VerificationRequestView />}
        />
        <Route path="users" element={<Users />} />
        <Route path="users/:id" element={<UserView />} />
        <Route path="create-notifications" element={<CreateNotifications />} />
        <Route path="shows" element={<Shows />} />
        <Route path="shows/:id" element={<ShowView />} />
        <Route path="settings" element={<Settings />} />
      </Route>
      <Route path="login" element={<Login />} />
    </Route>,
  ),
);
