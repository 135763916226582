import React, { useCallback, useState } from "react";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "16px 0",
});

const SearchField = ({ label, onSearch }) => {
  const [value, setValue] = useState("");

  const onChange = useCallback(
    (e) => {
      e.preventDefault();
      setValue(e.target.value);
      onSearch(e.target.value);
    },
    [onSearch],
  );

  const onClear = useCallback(() => {
    setValue("");
    onSearch("");
  }, [onSearch]);

  return (
    <Container>
      <FormControl variant="outlined" sx={{ minWidth: "250px" }}>
        <InputLabel>{label || "Search"}</InputLabel>

        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          endAdornment={
            <InputAdornment position="end">
              {!!value && (
                <IconButton aria-label={"search"} onClick={onClear} edge="end">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
          label={label || "Search"}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </Container>
  );
};

export default React.memo(SearchField);
