import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { createNotification } from "../../services/notifications.service";

const defaultValues = {
  inbox: false,
  push: false,
  allUsers: true,
  userId: "",
  message: "",
  title: "",
};

const Notifications = () => {
  const [values, setValues] = useState(defaultValues);
  const { isPending, isSuccess, isError, status, mutate, reset } = useMutation({
    mutationKey: ["createNotification"],
    mutationFn: (notificationData) => createNotification(notificationData),
  });

  const handleChange = useCallback((event) => {
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const handleCheckboxChange = useCallback((event, checked) => {
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: checked,
    }));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !values.message ||
      (!values.allUsers && !values.userId) ||
      (!values.inbox && !values.push)
    ) {
      alert(
        !values.message
          ? "Please, enter a message"
          : !values.allUsers && !values.userId
            ? "Please, add user id"
            : "Please, select a notification type",
      );
      return;
    }

    mutate({
      ...values,
    });
  };

  useEffect(() => {
    if (isSuccess || status === "success") {
      reset();
      setValues(defaultValues);

      alert("Success!");
    }
  }, [isSuccess, status, reset]);

  useEffect(() => {
    if (isError || status === "error") {
      alert("Error!");
    }
  }, [isError, status]);

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minHeight: "80px",
        }}
      >
        <FormControlLabel
          checked={values.inbox}
          onChange={handleCheckboxChange}
          control={<Checkbox />}
          label="Inbox"
          name="inbox"
          disabled={isPending}
        />

        <FormControlLabel
          checked={values.push}
          onChange={handleCheckboxChange}
          control={<Checkbox />}
          label="Push-notification"
          name="push"
          disabled={isPending}
        />

        <FormControlLabel
          checked={values.allUsers}
          onChange={handleCheckboxChange}
          control={<Checkbox />}
          label="All users"
          name="allUsers"
          disabled={isPending}
        />

        {!values.allUsers && (
          <TextField
            id="userId"
            name="userId"
            label="User id"
            style={{ flexGrow: 1 }}
            disabled={isPending}
            value={values.userId}
            onChange={handleChange}
            inputProps={{ maxLength: 100 }}
          />
        )}
      </Box>

      <TextField
        margin="normal"
        required
        fullWidth
        id="message"
        name="message"
        label="Message"
        disabled={isPending}
        value={values.message}
        onChange={handleChange}
        inputProps={{ maxLength: 120 }}
      />

      {values.push && (
        <TextField
          margin="normal"
          fullWidth
          id="title"
          name="title"
          label="Title (*push-notifications only)"
          disabled={isPending}
          value={values.title}
          onChange={handleChange}
          inputProps={{ maxLength: 30 }}
        />
      )}

      <Button
        type="submit"
        variant="outlined"
        disabled={isPending}
        sx={{ mt: 3, mb: 2 }}
      >
        Submit
      </Button>
    </Box>
  );
};

export default Notifications;
