import { useEffect } from "react";
import Container from "@mui/material/Container";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ErrorPageDetails } from "../../components/ErrorPageDetails";
import { Loading } from "../../components/Loading";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DetailsLabel from "../../components/DetailsLabel";
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import {
  getShow,
  finishShow,
  hideShow,
  ShowStatus,
} from "../../services/show.service";
import PriorityForm from "./PriorityForm";
import { getDuration } from "../../utils/helpers";

const InnerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
});
const RowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBlock: "16px",
  padding: 0,
});
const LeftSideContainer = styled(Box)({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  padding: "0 8px 0 0",
});
const RightSideContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 0 0 8px",
});
const ShowContainer = styled(Box)({
  position: "relative",
  width: "150px",
  height: "150px",
});
const Image = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 0,
});
const ChannelContainer = styled(Box)({
  position: "relative",
  width: "60px",
  height: "60px",
});

const ShowView = () => {
  const { id } = useParams();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["show", id],
    queryFn: () => getShow(id),
  });
  const {
    mutate: changeStatusToFinished,
    isPending: isFinishPending,
    isSuccess: isFinishSuccess,
    isError: isFinishError,
    reset: resetFinish,
  } = useMutation({
    mutationKey: ["finishShow", id],
    mutationFn: () => finishShow(id),
  });
  const {
    mutate: changeStatusToHidden,
    isPending: isHidePending,
    isSuccess: isHideSuccess,
    isError: isHideError,
    reset: resetHide,
  } = useMutation({
    mutationKey: ["hideShow", id],
    mutationFn: () => hideShow(id),
  });

  useEffect(() => {
    if (isFinishSuccess) {
      alert("Success");
      resetFinish();
      refetch();
    }
  }, [isFinishSuccess, resetFinish, refetch]);

  useEffect(() => {
    if (isHideSuccess) {
      alert("Success");
      resetHide();
      refetch();
    }
  }, [isHideSuccess, resetHide, refetch]);

  useEffect(() => {
    if (isFinishError || isHideError) {
      alert("Error");
    }
  }, [isFinishError, isHideError]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPageDetails text={"Error loading show"} />;
  }

  return (
    <Container component="main">
      <InnerContainer>
        <RowContainer>
          <Box>
            <Typography component="h1" variant="h4">
              Show Info
            </Typography>
            <ShowContainer>
              <Image src={data.image} alt={"Show image"} loading="lazy" />
            </ShowContainer>
            <DetailsLabel label={"Title"} value={data.title} />
            <DetailsLabel label={"Status"} value={data.status} />
            <DetailsLabel
              label={"Recorded"}
              value={data.recorded ? "Yes" : "No"}
            />
            <DetailsLabel
              label={"Listeners Count"}
              value={data.listenersCount}
            />
            <DetailsLabel label={"Min bid"} value={data.minBid} />
            <DetailsLabel
              label={"Price for listen"}
              value={data.priceForListen}
            />
            <DetailsLabel
              label={"Duration"}
              value={getDuration(data.createdDate, data.updatedDate) || "-"}
            />
            <DetailsLabel
              label={"Created Date"}
              value={format(data.createdDate, "MMM d, yyyy H:mm")}
            />

            {!!data?.channel?.id && (
              <Box sx={{ marginTop: 2 }}>
                <Typography component="h1" variant="h5">
                  Channel
                </Typography>

                <ChannelContainer>
                  <Image
                    src={data.channel.image}
                    alt={"Channel image"}
                    loading="lazy"
                  />
                </ChannelContainer>

                <DetailsLabel label={"Name"} value={data.channel.name} />
                <DetailsLabel
                  label={"Description"}
                  value={data.channel.discription || "-"}
                />
              </Box>
            )}
          </Box>

          <Box sx={{ ml: 2 }}>
            {data.status === ShowStatus.Live ? (
              <Button
                variant="outlined"
                disabled={isFinishPending || isHidePending}
                onClick={changeStatusToFinished}
              >
                Finish Show
              </Button>
            ) : data.status === ShowStatus.Finished && data.recorded ? (
              <Button
                variant="contained"
                disabled={isFinishPending || isHidePending}
                onClick={changeStatusToHidden}
              >
                Hide Show
              </Button>
            ) : null}

            {data.status === "hidden" ? null : (
              <PriorityForm
                id={id}
                currentPriority={
                  data?.manualSorting ? Math.floor(data?.manualSorting) : 0
                }
                onSuccess={refetch}
              />
            )}
          </Box>
        </RowContainer>

        <RowContainer>
          <LeftSideContainer>
            <Typography component="h1" variant="h4">
              Host Info
            </Typography>
            <DetailsLabel
              label={"Username"}
              value={data.host.username ? `@${data.host.username}` : "-"}
            />
            <DetailsLabel label={"Display Name"} value={data.host.name} />
          </LeftSideContainer>

          {!data.coHost ? null : (
            <RightSideContainer>
              <Typography component="h1" variant="h4">
                Co-host Info
              </Typography>
              <DetailsLabel
                label={"Username"}
                value={data.coHost.username ? `@${data.coHost.username}` : "-"}
              />
              <DetailsLabel label={"Display Name"} value={data.coHost.name} />
            </RightSideContainer>
          )}
        </RowContainer>

        <RowContainer>
          <LeftSideContainer>
            <Typography component="h1" variant="h4">
              Topics
            </Typography>
            {!data.topics?.length
              ? null
              : data.topics.map((topic) => (
                  <Box key={topic.id} sx={{ marginBottom: 2 }}>
                    <DetailsLabel label={"Title"} value={topic.title} />
                    <DetailsLabel
                      label={"Blue side question"}
                      value={topic.blueSide}
                    />
                    <DetailsLabel
                      label={"Red side question"}
                      value={topic.redSide}
                    />
                    <DetailsLabel
                      label={"Created At"}
                      value={format(topic.createdAt, "MMM d, yyyy H:mm")}
                    />
                    <DetailsLabel
                      label={"Ended At"}
                      value={
                        topic.endedAt
                          ? format(topic.endedAt, "MMM d, yyyy H:mm")
                          : "-"
                      }
                    />
                  </Box>
                ))}
          </LeftSideContainer>

          {data.status === "live" ? (
            <RightSideContainer>
              <Typography component="h1" variant="h4">
                Current Speakers
              </Typography>
              {!data.speakers?.length
                ? null
                : data.speakers.map((speaker) => (
                    <DetailsLabel
                      key={speaker.id}
                      label={`${speaker.side === "blue" ? "Blue" : "Red"} side`}
                      value={
                        speaker.user.username
                          ? `@${speaker.user.username}`
                          : "-"
                      }
                    />
                  ))}
            </RightSideContainer>
          ) : null}
        </RowContainer>
      </InnerContainer>
    </Container>
  );
};

export default ShowView;
