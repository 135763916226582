import DetailsLabel from "../../components/DetailsLabel";
import { IconButton, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import Box from "@mui/material/Box";
import { useCallback, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { useMutation } from "@tanstack/react-query";
import { updateShowPriority } from "../../services/show.service";

const PriorityContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  marginBlock: "16px",
  padding: 0,
});

const PriorityForm = ({ id, currentPriority, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [priorityValue, setPriorityValue] = useState(currentPriority);
  const sx = useMemo(() => {
    return {
      "& .MuiOutlinedInput-root": {
        maxWidth: "60px",
        "& fieldset": {
          border: !isEditing ? "none" : "",
        },
        "& input": {
          boxSizing: "border-box",
        },
      },
      mr: 2,
    };
  }, [isEditing]);
  const {
    mutate: updatePriority,
    isPending,
    isSuccess,
    isError,
    reset,
  } = useMutation({
    mutationKey: ["updatePriority", id],
    mutationFn: (value) => updateShowPriority({ id, value }),
  });

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const onChange = useCallback((event) => {
    const newValue = event.target.value;

    if (/^\d*$/.test(newValue)) {
      setPriorityValue(Number(event.target.value));
    }
  }, []);

  const onClose = useCallback(() => {
    setPriorityValue(currentPriority);
    setIsEditing(false);
  }, [currentPriority]);

  const onUpdate = useCallback(() => {
    if (priorityValue === currentPriority) {
      return;
    }

    updatePriority(priorityValue);
    setIsEditing(false);
  }, [priorityValue, currentPriority, updatePriority]);

  useEffect(() => {
    if (isSuccess) {
      alert("Success");
      onSuccess();
      reset();
    }
  }, [isSuccess, reset, onSuccess]);

  useEffect(() => {
    if (isError) {
      alert("Error");
    }
  }, [isError]);
  return (
    <PriorityContainer>
      <DetailsLabel label={"Priority"} />

      <TextField
        required
        id="value"
        name="value"
        disabled={!isEditing || isPending}
        value={priorityValue}
        onChange={onChange}
        sx={sx}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />

      {isEditing ? (
        <>
          <IconButton
            variant="contained"
            onClick={onUpdate}
            disabled={isPending}
            sx={{ marginInline: 1 }}
          >
            <CheckIcon />
          </IconButton>

          <IconButton
            variant="contained"
            onClick={onClose}
            disabled={isPending}
            sx={{ marginInline: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </>
      ) : (
        <IconButton
          variant="contained"
          onClick={onEdit}
          disabled={isPending}
          sx={{ marginInline: 1 }}
        >
          <CreateIcon />
        </IconButton>
      )}
    </PriorityContainer>
  );
};

export default PriorityForm;
