import { format } from "date-fns";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getShows } from "../../services/show.service";
import CustomTable from "../../components/CustomTable";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { StyledTableCell } from "../../components/styled";

const renderHeader = () => (
  <TableRow>
    <StyledTableCell>Title</StyledTableCell>
    <StyledTableCell>Status</StyledTableCell>
    <StyledTableCell>Average Rating</StyledTableCell>
    <StyledTableCell>Created Date</StyledTableCell>
    <StyledTableCell>Action</StyledTableCell>
  </TableRow>
);

const renderRow = (show) => (
  <TableRow key={show.id}>
    <TableCell>{show.title}</TableCell>
    <TableCell>{show.status}</TableCell>
    <TableCell>{show.averageRating || "N/A"}</TableCell>
    <TableCell>{format(show.createdDate, "MMM d, yyyy H:mm")}</TableCell>
    <TableCell>
      <IconButton aria-label="view" component={Link} to={`${show.id}`}>
        <VisibilityIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const Shows = () => {
  return (
    <CustomTable
      name={"shows"}
      getItemsMethod={getShows}
      renderHeader={renderHeader}
      renderRow={renderRow}
      columnCount={5}
      searchLabel={"Search by Title"}
      isSearchable
    />
  );
};

export default Shows;
