import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getVerificationRequest,
  changeVerificationRequestStatus,
  VerificationRequestStatus,
} from "../../services/verificationRequest.service";
import { Modal } from "@mui/material";
import DetailsLabel from "../../components/DetailsLabel";
import { format } from "date-fns";
import { ErrorPageDetails } from "../../components/ErrorPageDetails";
import { Loading } from "../../components/Loading";

const InnerContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
});
const LeftSideContainer = styled(Box)({
  flex: 2,
  display: "flex",
  flexDirection: "column",
  padding: "0 8px",
});
const RightSideContainer = styled(Box)({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  padding: "0 8px",
});
const AvatarContainer = styled(Box)({
  position: "relative",
  width: "150px",
  height: "150px",
});
const AvatarBackground = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 0,
});
const AvatarImage = styled("img")({
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 10,
});
const IdentityDocumentImage = styled("img")({
  position: "relative",
  height: "200px",
});
const IdentityDocumentBigImageContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
});
const IdentityDocumentBigImage = styled("img")({
  height: "70%",
});

const VerificationRequestView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["verificationRequest", id],
    queryFn: () => getVerificationRequest(id),
  });
  const {
    mutate: changeStatus,
    isPending,
    isSuccess: isChangeStatusSuccess,
  } = useMutation({
    mutationKey: ["verificationRequestStatus", id],
    mutationFn: (status) => changeVerificationRequestStatus(id, status),
  });
  const [isOpenedDocumentModal, setIsOpenedDocumentModal] = useState(false);
  const handleOpenDocumentModal = () => setIsOpenedDocumentModal(true);
  const handleCloseDocumentModal = () => setIsOpenedDocumentModal(false);

  useEffect(() => {
    if (isChangeStatusSuccess) {
      navigate(-1);
    }
  }, [isChangeStatusSuccess, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPageDetails text={"Error loading Verification request"} />;
  }

  return (
    <Container component="main">
      <InnerContainer>
        <LeftSideContainer>
          <Typography component="h1" variant="h4">
            Request Info
          </Typography>
          <Box>
            <Button onClick={handleOpenDocumentModal}>
              <IdentityDocumentImage
                src={data.identityDocument}
                alt={"Identity document"}
                loading="lazy"
              />
            </Button>
          </Box>
          <DetailsLabel label={"Name"} value={data.fullName} />
          <DetailsLabel label={"Role"} value={data.role} />
          <DetailsLabel label={"Status"} value={data.status} />
          <DetailsLabel
            label={"Created at"}
            value={format(data.createdAt, "MMM d, yyyy H:mm")}
          />
          <DetailsLabel
            label={"Birthday"}
            value={format(data.birthday, "MMM d, yyyy H:mm")}
          />
          <DetailsLabel label={"Contact"} value={data.contact} />
          <DetailsLabel
            label={"Statement of intent"}
            value={data.statementOfIntent}
          />
          {data.club && <DetailsLabel label={"Club"} value={data.club} />}
          {data.position && (
            <DetailsLabel label={"Position"} value={data.position} />
          )}
          {data.experienceStartedAt && (
            <DetailsLabel
              label={"Experience started at"}
              value={format(data.experienceStartedAt, "MMM d, yyyy H:mm")}
            />
          )}
          {data.experienceEndedAt && (
            <DetailsLabel
              label={"Experience ended at"}
              value={format(data.experienceEndedAt, "MMM d, yyyy H:mm")}
            />
          )}
        </LeftSideContainer>
        <RightSideContainer>
          <Typography component="h1" variant="h4">
            Profile Info
          </Typography>
          <AvatarContainer>
            <AvatarBackground
              src={data.user.background}
              alt={"User background"}
              loading="lazy"
            />
            <AvatarImage
              src={data.user.image}
              alt={"User avatar"}
              loading="lazy"
            />
          </AvatarContainer>
          <DetailsLabel label={"Name"} value={data.user.name} />
          <DetailsLabel label={"Username"} value={data.user.username} />
          <DetailsLabel label={"Score"} value={data.user.score} />
          <DetailsLabel
            label={"Created at"}
            value={format(data.createdAt, "MMM d, yyyy H:mm")}
          />
        </RightSideContainer>
      </InnerContainer>
      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: "16px",
        }}
      >
        <Button
          variant="outlined"
          disabled={isPending}
          onClick={() => changeStatus(VerificationRequestStatus.Cancelled)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isPending}
          onClick={() => changeStatus(VerificationRequestStatus.Fulfilled)}
        >
          Approve
        </Button>
      </Stack>

      <Modal
        open={isOpenedDocumentModal}
        onClose={handleCloseDocumentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <IdentityDocumentBigImageContainer onClick={handleCloseDocumentModal}>
          <IdentityDocumentBigImage
            src={data.identityDocument}
            alt={"Identity document"}
            loading="lazy"
          />
        </IdentityDocumentBigImageContainer>
      </Modal>
    </Container>
  );
};

export default VerificationRequestView;
