import { apiGet, apiPost, apiPut } from "../utils";

export const login = async (data) => {
  const response = await apiPost("/auth/admin/login", data);
  const { message, ...user } = await response.json();

  if (message) {
    switch (message) {
      case "invalid_email_or_password":
        throw "Invalid credentials";
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return user;
};

export const getUsers = async (page, perPage, search = "") => {
  const response = await apiGet("/admin/users", { page, perPage, search });
  const { items, totalCount, message } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return { items, totalCount };
};

export const getUserById = async (id) => {
  const response = await apiGet(`/admin/users/${id}`);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const updateUserRole = async (id, action) => {
  const response = await apiPut(`/admin/users/${id}/${action}`);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};
