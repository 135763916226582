import Container from "@mui/material/Container";
import { CircularProgress } from "@mui/material";

export const Loading = () => (
  <Container
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }}
  >
    <CircularProgress />
  </Container>
);
