import { styled } from "@mui/material/styles";

const Container = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
});
const Text = styled("span")({
  fontWeight: "bold",
  fontSize: "24px",
});

const Welcome = () => {
  return (
    <Container>
      <Text>Welcome to Omili Admin panel!</Text>
    </Container>
  );
};

export default Welcome;
