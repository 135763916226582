import { differenceInYears, format } from "date-fns";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getUsers } from "../../services/user.service";
import CustomTable from "../../components/CustomTable";
import { StyledTableCell } from "../../components/styled";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";

const renderHeader = () => (
  <TableRow>
    <StyledTableCell>Id</StyledTableCell>
    <StyledTableCell>Username</StyledTableCell>
    <StyledTableCell>Display Name</StyledTableCell>
    <StyledTableCell>Verified as</StyledTableCell>
    <StyledTableCell>Date of Birth</StyledTableCell>
    <StyledTableCell>Created Date</StyledTableCell>
    <StyledTableCell>Action</StyledTableCell>
  </TableRow>
);

const renderRow = (user) => (
  <TableRow key={user.id}>
    <TableCell>{user.id}</TableCell>
    <TableCell>{user.username ? `@${user.username}` : ""}</TableCell>
    <TableCell>{user.name}</TableCell>
    <TableCell>{user.verifiedAs || "-"}</TableCell>
    <TableCell>
      {user.birthday
        ? `${format(user.birthday, "MMM d, yyyy")} (${differenceInYears(new Date(), new Date(user.birthday))} years old)`
        : "N/A"}
    </TableCell>
    <TableCell>{format(user.createdDate, "MMM d, yyyy H:mm")}</TableCell>
    <TableCell>
      <IconButton aria-label="view" component={Link} to={`${user.id}`}>
        <VisibilityIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const Users = () => {
  return (
    <CustomTable
      name={"users"}
      getItemsMethod={getUsers}
      renderHeader={renderHeader}
      renderRow={renderRow}
      columnCount={7}
      searchLabel={"Search by Display Name"}
      isSearchable
    />
  );
};

export default Users;
