import { apiGet, apiPut } from "../utils";

export const ShowStatus = {
  Live: "live",
  Finished: "finished",
  Recorded: "recorded",
};

export const getShows = async (page, perPage, search = "") => {
  const response = await apiGet("/admin/shows", { page, perPage, search });
  const { items, totalCount, message } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return { items, totalCount };
};

export const getShow = async (id) => {
  const response = await apiGet(`/admin/shows/${id}`);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const finishShow = async (id) => {
  const response = await apiPut(`/shows/${id}/finish`);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const hideShow = async (id) => {
  const response = await apiPut(`/admin/shows/${id}/hide`, { value: 0 });
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const updateShowPriority = async ({ id, value }) => {
  const response = await apiPut(`/admin/shows/${id}/prioritise`, { value });
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};
