import { intervalToDuration } from "date-fns";

const addZero = (value) => {
  if (!value) {
    return "00";
  }

  return value < 10 ? `0${value}` : value;
};

export const getDuration = ({ start, end }) => {
  let duration = "";

  if (!start || !end) {
    return duration;
  }

  const { days, hours, minutes, seconds } = intervalToDuration({ start, end });

  if (days && days > 0) {
    duration = duration.concat(`${addZero(days)}:`);
  }

  if (hours && hours > 0) {
    duration = duration.concat(`${addZero(hours)}:`);
  }

  duration = duration.concat(`${addZero(minutes)}:${addZero(seconds)}`);

  return duration;
};
