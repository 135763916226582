import { apiGet, apiPut, apiDelete } from "../utils";

export const getAppConfig = async () => {
  const response = await apiGet(`/app-config`);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const updateAppConfigItem = async (item) => {
  const response = await apiPut(`/admin/app-config`, { item });
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const deleteAppConfigItem = async (params) => {
  const response = await apiDelete(`/admin/app-config`, params);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};
