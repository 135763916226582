import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { differenceInYears, format } from "date-fns";
import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getUserById, updateUserRole } from "../../services/user.service";
import { Loading } from "../../components/Loading";
import { ErrorPageDetails } from "../../components/ErrorPageDetails";
import DetailsLabel from "../../components/DetailsLabel";

const InnerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
});
const RowContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginBlock: "16px",
  padding: 0,
});
const AvatarContainer = styled(Box)({
  position: "relative",
  width: "150px",
  height: "150px",
});
const AvatarBackground = styled("img")({
  position: "absolute",
  width: "100%",
  height: "100%",
  zIndex: 0,
});
const AvatarImage = styled("img")({
  position: "relative",
  width: "100%",
  height: "100%",
  zIndex: 10,
});

const UserView = () => {
  const { id } = useParams();
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["getUser", id],
    queryFn: () => getUserById(id),
  });
  const {
    mutate: updateRole,
    isPending,
    isSuccess,
    isError: isUpdateError,
  } = useMutation({
    mutationKey: ["updateUserRole", id],
    mutationFn: (action) => updateUserRole(id, action),
  });

  useEffect(() => {
    if (isSuccess) {
      alert("Success");
      refetch();
    }
  }, [isSuccess, refetch]);

  useEffect(() => {
    if (isUpdateError) {
      alert("Error");
    }
  }, [isUpdateError]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !data) {
    return <ErrorPageDetails text={"Error loading user data"} />;
  }

  return (
    <Container component="main">
      <InnerContainer>
        <RowContainer>
          <Box>
            <Typography component="h1" variant="h4">
              User Info
            </Typography>
            {data?.image && (
              <AvatarContainer>
                <AvatarBackground
                  src={data.background}
                  alt={"User background"}
                  loading="lazy"
                />
                <AvatarImage
                  src={data.image}
                  alt={"User avatar"}
                  loading="lazy"
                />
              </AvatarContainer>
            )}
            <DetailsLabel
              label={"Username"}
              value={data?.username ? `@${data.username}` : "N/A"}
            />
            <DetailsLabel label={"Email"} value={data?.email} />
            <DetailsLabel label={"Display Name"} value={data?.name} />
            <DetailsLabel
              label={"Verified as"}
              value={data?.verifiedAs || "-"}
            />
            <DetailsLabel label={"Bio"} value={data?.bio || "-"} />
            <DetailsLabel label={"Country"} value={data?.country || "-"} />
            <DetailsLabel label={"Team"} value={data?.team.name} />
            <DetailsLabel label={"Score"} value={data?.score || "-"} />
            <DetailsLabel label={"Followers"} value={data?.followers || "-"} />
            <DetailsLabel
              label={"Total Likes"}
              value={data?.totalLikes || "-"}
            />
            <DetailsLabel
              label={"Shows Hosted"}
              value={data?.showsHosted || "-"}
            />
            <DetailsLabel
              label={"Shows Visited"}
              value={data?.showsVisited || "-"}
            />
            <DetailsLabel
              label={"Debates Won"}
              value={data?.debatesWon || "-"}
            />
            <DetailsLabel
              label={"Date of birth"}
              value={
                data?.birthday
                  ? `${format(data.birthday, "MMM d, yyyy")} (${differenceInYears(new Date(), new Date(data.birthday))} years old)`
                  : "N/A"
              }
            />
            <DetailsLabel
              label={"Created Date"}
              value={format(data.createdDate, "MMM d, yyyy H:mm")}
            />
          </Box>

          <Box sx={{ ml: 2 }}>
            {data.roles.includes("blocked") ? (
              <Button
                variant="outlined"
                disabled={isPending}
                onClick={() => updateRole("unblock")}
              >
                Unblock
              </Button>
            ) : (
              <Button
                variant="contained"
                disabled={isPending}
                onClick={() => updateRole("block")}
              >
                Block
              </Button>
            )}
          </Box>
        </RowContainer>
      </InnerContainer>
    </Container>
  );
};

export default UserView;
