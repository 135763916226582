import { apiGet, apiPut } from "../utils";

export const ReportStatus = {
  Pending: "pending",
  Fulfilled: "fulfilled",
  Ignored: "ignored",
  UserBlocked: "userBlocked",
  UserRestored: "userRestored",
};

export const getReports = async (page, perPage) => {
  const response = await apiGet("/reports", { page, perPage });
  const { items, totalCount, message } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return { items, totalCount };
};

export const getReport = async (id) => {
  const response = await apiGet(`/reports/${id}`);
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};

export const changeReportStatus = async (id, status) => {
  const response = await apiPut(`/reports/${id}/status`, {
    status,
  });
  const { message, ...report } = await response.json();

  if (message) {
    switch (message) {
      case "access_denied":
        throw "Access denied";
      default:
        throw message;
    }
  }

  return report;
};
