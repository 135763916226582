import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import { CircularProgress, TablePagination } from "@mui/material";
import SearchField from "./SearchField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";

const CustomTable = ({
  name,
  getItemsMethod,
  renderHeader,
  renderRow,
  columnCount,
  searchLabel,
  isSearchable = false,
}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [search, setSearch] = useState("");
  const { data, isLoading, isError } = useQuery({
    queryKey: [name, getItemsMethod, page, perPage, search],
    queryFn: () => getItemsMethod(page, perPage, search),
  });

  const setSearchedDataDebounced = useMemo(
    () =>
      debounce((_text) => {
        setSearch(_text);
      }, 600),
    [],
  );

  const onPageChange = (_, newPage) => {
    setPage(newPage);
  };

  const onPerPageChange = (event) => {
    setPerPage(event.target.value);
  };

  if (isError) {
    return <Typography variant="subtitle2">Error loading {name}</Typography>;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {isSearchable && (
          <SearchField
            label={searchLabel}
            onSearch={setSearchedDataDebounced}
          />
        )}

        <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
          <Table size="small">
            <TableHead>{renderHeader()}</TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columnCount} align="center">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="100px"
                    >
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : data.items.length ? (
                data.items.map(renderRow)
              ) : (
                <TableRow>
                  <TableCell colSpan={columnCount} align="center">
                    <Typography variant="subtitle1" color="textSecondary">
                      No data available
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={data?.totalCount || 0}
            page={page}
            onPageChange={onPageChange}
            rowsPerPage={perPage}
            onRowsPerPageChange={onPerPageChange}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CustomTable;
