import { useCallback, useState } from "react";
import { Box, Button, Container, styled } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AddIcon from "@mui/icons-material/Add";
import { ErrorPageDetails } from "../../components/ErrorPageDetails";
import { Loading } from "../../components/Loading";
import { getAppConfig } from "../../services/app.service";
import SettingsItem from "./Item";
import SettingsForm from "./Form";

const InnerContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  marginBlock: "16px",
});

const Settings = () => {
  const [isFormShown, setIsFormShown] = useState(false);
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["app-config"],
    queryFn: () => getAppConfig(),
  });

  const onSuccess = useCallback(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorPageDetails text={"Error loading app config"} />;
  }

  return (
    <Container component="main">
      <Box>
        {data
          ? Object.entries(data).map((configItem) => (
              <SettingsItem
                key={configItem[0]}
                label={configItem[0]}
                currentValue={configItem[1]}
                onSuccess={onSuccess}
              />
            ))
          : null}
      </Box>

      <InnerContainer>
        {!isFormShown && (
          <Button
            variant="outlined"
            onClick={() => setIsFormShown(true)}
            startIcon={<AddIcon />}
          >
            Add
          </Button>
        )}

        <SettingsForm
          isShows={isFormShown}
          setIsShown={setIsFormShown}
          onSuccess={onSuccess}
        />
      </InnerContainer>
    </Container>
  );
};

export default Settings;
