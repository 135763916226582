export const api = (url, params = {}, headers = {}) => {
  const token = localStorage.getItem("token");
  return fetch(`${process.env.REACT_APP_API_URL}/api/v1${url}`, {
    ...params,
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const apiGet = (url, params = {}) => {
  const urlParams = Object.entries(params).reduce(
    (acc, [key, value]) => `${acc}${key}=${encodeURIComponent(value)}&`,
    "",
  );
  return api(`${url}?${urlParams}`, {
    method: "GET",
    params: JSON.stringify(params),
  });
};

export const apiPost = (url, params = {}) =>
  api(url, {
    method: "POST",
    body: JSON.stringify(params),
  });

export const apiPut = (url, params = {}) =>
  api(url, {
    method: "PUT",
    body: JSON.stringify(params),
  });

export const apiDelete = (url, params = {}) =>
  api(url, {
    method: "DELETE",
    body: JSON.stringify({ params }),
  });
