import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Container = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  margin: "4px 0",
});
const Label = styled(Typography)({
  marginRight: "8px",
  borderRadius: "4px",
  opacity: 0.6,
});

const DetailsLabel = ({ label, value }) => {
  return (
    <Container>
      <Label variant="h6">{label}:</Label>
      <Typography variant="h6">{value}</Typography>
    </Container>
  );
};

export default DetailsLabel;
