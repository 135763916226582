import { format } from "date-fns";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getReports } from "../../services/report.service";
import CustomTable from "../../components/CustomTable";
import { Link } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { StyledTableCell } from "../../components/styled";

const renderHeader = () => (
  <TableRow>
    <StyledTableCell>Incident Date</StyledTableCell>
    <StyledTableCell>Status</StyledTableCell>
    <StyledTableCell>Type</StyledTableCell>
    <StyledTableCell>Description</StyledTableCell>
    <StyledTableCell>Show</StyledTableCell>
    <StyledTableCell>Reporter</StyledTableCell>
    <StyledTableCell>Target</StyledTableCell>
    <StyledTableCell>Actions</StyledTableCell>
  </TableRow>
);

const renderRow = (report) => (
  <TableRow key={report.id}>
    <TableCell>{format(report.incidentDate, "MMM d, yyyy H:mm")}</TableCell>
    <TableCell>{report.status}</TableCell>
    <TableCell>{report.type}</TableCell>
    <TableCell>{report.description}</TableCell>
    <TableCell>{report.show.title}</TableCell>
    <TableCell>{report.source.name}</TableCell>
    <TableCell>{report.target.name}</TableCell>
    <TableCell>
      <IconButton aria-label="view" component={Link} to={`${report.id}`}>
        <VisibilityIcon />
      </IconButton>
    </TableCell>
  </TableRow>
);

const Reports = () => {
  return (
    <CustomTable
      name={"reports"}
      getItemsMethod={getReports}
      renderHeader={renderHeader}
      renderRow={renderRow}
      columnCount={8}
    />
  );
};

export default Reports;
